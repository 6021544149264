body {
  font-family: "Proxima Nova", "Helvetica Neue", Arial, sans-serif;
}

#save, #back {
  z-index: 1500;
}

#phone {
  width: 100%;
  height: calc(100vh - var(--toolbar-height));
}

#collage footer {
  height: 80px;
  --toolbar-height: 80px; /* Example height for the toolbar */
}

@media screen and (max-width: 768px) {
  #collage .wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it stays above other content */
  }
  body {
    overscroll-behavior: contain;
  }

  #colors {
    margin-top: -50px !important;
    z-index: 1000;
  }

  h1 {
    font-size: 25px !important;
    text-decoration-style: capitalize;
    margin-bottom: 0px !important;
    color: black;
    font-weight: 800;
  }

  h4 {
    font-size: 12px !important;
    top-margin: 0px !important;
    color: black;
    font-weight: 800;
  }

  header button {
    font-size: 12px !important;
  }
}

/* Animation for the rotation */
@keyframes rotate-hover {
  0% {
    transform: rotate(-5deg); /* Slight left rotation */
  }
  50% {
    transform: rotate(5deg); /* Slight right rotation */
  }
  100% {
    transform: rotate(-5deg); /* Back to left rotation */
  }
}

.oversize {
  font-size: 128px !important;
  font-weight: 700;
}

.bg-yis-grey {
  background-color: #272626;
}
.bg-yis-purple {
  background-color: #FFEE51;
}

.active {
  
}

.pink-bg {
  background-color: #FD267D;
}

.purple-bg {
  background-color: #974DE7;
}

.blue-bg {
  background-color: #2961EC;
}

.yellow-bg {
  background-color: #FFEE51;
}

.grey-bg {
  background-color: #272626;
}

.btn-pink {
  background-color: #FD267D;
  box-shadow: 5px 5px 1px #47A1FF;
  color: white;
  font-weight: 800;
}

.btn-purple {
  background-color: none;
  box-shadow: none;
  border-width: 2px;
  border-color: white;
  color: white;
  font-weight: 800;
}

.btn-yellow-modal {
  background-color: #FFEE51;
  color: white;
  font-weight: 800;
}

.btn-yellow {
  background-color: none;
  box-shadow: none;
  border-width: 2px;
  border-color: white;
  color: white;
  font-weight: 800;
}

.btn-yellow:hover {
  background-color: #FFEE51;
  box-shadow: 5px 5px 1px #974DE7;
  border: 2px #974DE7 solid;
  color: black;
  font-weight: 800;
}

.btn-blue:hover {
  background-color: #2961EC;
  box-shadow: 5px 5px 1px #30F3EF;
  border: 2px #30F3EF solid;
  color: white;
  font-weight: 800;
}

.btn-cyan:hover {
  background-color: #30F3EF;
  box-shadow: 5px 5px 1px #FF0DB3;
  border: 2px #FF0DB3 solid;
  color: black;
  font-weight: 800;
}

.btn-orange:hover {
  background-color: #FF7854;
  box-shadow: 5px 5px 1px #FF0DB3;
  border: 2px #FF0DB3 solid;
  color: black;
  font-weight: 800;
}

.btn-purple:hover {
  background-color: #974DE7;
  box-shadow: 5px 5px 1px #FD267D;
  border: 2px #FD267D solid;
  color: white;
  font-weight: 800;
}

.btn-outline {
  box-shadow: none;
  border-width: 2px;
  border-color: white;
  color: white;
  font-weight: 800;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 35px !important;
    text-decoration-style: capitalize;
    margin-bottom: 0px !important;
    color: black;
    font-weight: 800;
  }

  h4 {
    font-size: 16px !important;
    top-margin: 0px !important;
    color: black;
    font-weight: 800;
  }
}

.yellow-drop {text-shadow: 4px 4px 1px #FFEE51;}
.blue-drop {text-shadow: 4px 4px 1px #30F3EF;}
.pink-drop {text-shadow: 4px 4px 1px #FD267D;}

.modal h1 {
  font-size: 48px;  
}

.modal h4 {
  font-size: 24px;  
}

/* Modal Background (optional) */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fff; /* Light off-white color like paper */
  background-image: linear-gradient(to bottom, #cecece 1px, transparent 1px);
  background-size: 100% 24px; /* Spacing between lines */
  width: 80%; /* Adjust width as needed */
  max-width: 800px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  overflow: hidden;
  padding: 40px;
}
h1 {
  font-size: 68px;
}
@media (min-width: 786px){
  .modal-content {
    padding: 100px;
  }
  /* Left Margin Line */
  .modal-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 70px; /* Adjust to set margin width */
    width: 2px;
    height: 100%;
    background-color: #FD267D; /* Line color */
  }
}
/* Left Margin Line */
.modal-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 35px; /* Adjust to set margin width */
  width: 2px;
  height: 100%;
  background-color: #FD267D; /* Line color */
}

/* Text Styling (optional) */
.modal-content p {
  margin: 0;
  color: #333;
  line-height: 1.5;
  font-size: 12px;
}
